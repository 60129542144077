let shadowRoot; // Declare shadowRoot globally

// Wait for heyflow-init to initialize shadowRoot
window.addEventListener("heyflow-init", (event) => {
    shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`).shadowRoot) ||
    document; // Use document as a fallback for non-embedded flows

    console.log("Shadow root initialized:", shadowRoot);

    // Call functions that rely on shadowRoot after it's initialized
    initializeCustomScripts();
});

// Function to encapsulate custom scripts dependent on shadowRoot
function initializeCustomScripts() {
    // IP address population
    window.addEventListener("heyflow-screen-view", (event) => {
        try {
            if (event.detail.screenName === "registration") {
                const ipAddressField = shadowRoot.querySelector("#ipaddress");
                fetch("https://checkip.amazonaws.com/")
                .then((res) => res.text())
                .then((data) => {
                    if (ipAddressField) {
                        ipAddressField.value = data.trim();
                    }
                });
            }
        } catch (err) {
            console.error("Error in heyflow-screen-view event:",
                err);
        }
    });

    // Modal and content handling
    const urlParams = new URLSearchParams(window.location.search);
    let company = urlParams.get("company") || "default"; // Use 'default' if no company is provided
    company = company.split(" ")[0]; // Use only the first word of the company variable

    const content = {
        Naturvel: {
            imprint: "https://www.123-tongain.com/sc/todo=show_html&html_pk=17",
            privacy: "https://www.123-tongain.com/sc/todo=show_html&html_pk=18",
            terms: "https://www.123-tongain.com/sc/todo=show_html&html_pk=13",
        },
        toleadoo: {
            imprint: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=19",
            privacy: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=20",
            terms: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=16",
        },
        default: {
            imprint: "https://budgetefficace.com/imprint-budgetefficace",
            privacy: "https://budgetefficace.com/privacypolicy-budgetefficace",
            terms: "https://budgetefficace.com/terms-and-conditions-budgetefficace",
        },
        };

        const companyContent = content[company];

        shadowRoot.querySelector("#privacy-content").innerHTML = `<iframe src="${companyContent.privacy}" frameborder="0"></iframe>`;
        shadowRoot.querySelector("#terms-content").innerHTML = `<iframe src="${companyContent.terms}" frameborder="0"></iframe>`;
        shadowRoot.querySelector("#imprint-content").innerHTML = `<iframe src="${companyContent.imprint}" frameborder="0"></iframe>`;

        // Modal functionality
        const modals = {
            privacy: shadowRoot.querySelector("#privacy-modal"),
            terms: shadowRoot.querySelector("#terms-modal"),
            imprint: shadowRoot.querySelector("#imprint-modal"),
        };

        shadowRoot.querySelector("#privacy-link").onclick = function () {
            modals.privacy.style.display = "block";
        };
        // If there is no optin checkbox on your flow, delete this part from here...
        shadowRoot.querySelector("#privacy-link-chbx").onclick = function () {
            modals.privacy.style.display = "block";
        };
        //...until here.

        shadowRoot.querySelector("#terms-link").onclick = function () {
            modals.terms.style.display = "block";
        };

        shadowRoot.querySelector("#imprint-link").onclick = function () {
            modals.imprint.style.display = "block";
        };

        shadowRoot.querySelector("#close-privacy").onclick = function () {
            modals.privacy.style.display = "none";
        };

        shadowRoot.querySelector("#close-terms").onclick = function () {
            modals.terms.style.display = "none";
        };

        shadowRoot.querySelector("#close-imprint").onclick = function () {
            modals.imprint.style.display = "none";
        };

        window.onclick = function (event) {
            if (event.target == modals.privacy) {
                modals.privacy.style.display = "none";
            }
            if (event.target == modals.terms) {
                modals.terms.style.display = "none";
            }
            if (event.target == modals.imprint) {
                modals.imprint.style.display = "none";
            }
        };

        const form = shadowRoot.querySelector("form");
        const checkbox = shadowRoot.querySelector("#optin");
        const errorMessage = shadowRoot.querySelector("#checkbox-error");

        form.addEventListener("submit", function (event) {
            let isValid = true;

            if (!checkbox.checked) {
                isValid = false;
                checkbox.classList.add("error");
                errorMessage.textContent =
                "Vous devez accepter les conditions pour continuer.";
                errorMessage.classList.add("visible");
            }

            const genderOptions = shadowRoot.querySelectorAll('input[data-label="Gender"]');
            const genderError = shadowRoot.querySelector("#gender-error");
            const genderSelected = Array.from(genderOptions).some((option) => option.checked);

            if (!genderSelected) {
                isValid = false;
                genderError.style.display = "block";
            } else {
                genderError.style.display = "none";
            }

            if (!isValid) {
                event.preventDefault();
            }
        });

        checkbox.addEventListener("change",
            function () {
                if (checkbox.checked) {
                    checkbox.classList.remove("error");
                    errorMessage.classList.remove("visible");
                }
            });

        const genderOptions = shadowRoot.querySelectorAll('input[data-label="Gender"]');
        genderOptions.forEach((option) => {
            option.addEventListener("change", function () {
                const genderError = shadowRoot.querySelector("#gender-error");
                genderError.style.display = "none";
            });
        });

        const dateInput = shadowRoot.querySelector('input[data-label="dateofbirth"]');
        const errorContainer = shadowRoot.querySelector('#dob-error');

        if (dateInput && errorContainer) {
            const maxDate = new Date("2006-12-31");

            dateInput.addEventListener("change", function () {
                const selectedDate = new Date(dateInput.value);

                if (selectedDate > maxDate) {
                    dateInput.value = "2006-12-31";
                    errorContainer.style.display = "block";
                } else {
                    errorContainer.style.display = "none";
                }
            });
        } else {
            console.error("Datepicker input or error container not found. Check the selectors.");
        }


    }